import store from '../store'

const TokenKey = 'Admin-Token'
const TokenExpiration = 'Token-Expiration'
const RefreshToken = 'Refresh-Token'

export function getApiUrl() {
  var domain = ''
  if(location.host == process.env.VUE_APP_HOST_PROD)      //PROD
    domain = process.env.VUE_APP_API_DOMAIN_PROD
  else if(location.host == process.env.VUE_APP_HOST_UAT)  //UAT
    domain = process.env.VUE_APP_API_DOMAIN_UAT
  else if(location.host == process.env.VUE_APP_HOST_DEV || location.host.includes('localhost') )   //DEV
    domain = process.env.VUE_APP_API_DOMAIN_DEV

  return {
    'domain' : domain,
    'base_api' : domain + '/v1/newlos/',
    'environment' : (location.host==process.env.VUE_APP_HOST_PROD) ? 'PRODUCTION' : 'DEVELOPMENT'
  }
}

export function getToken() {
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}

// Token Expiration
export function getTokenExpiration() {
  return sessionStorage.getItem(TokenExpiration)
}
export function setTokenExpiration(tokenExp) {
  return sessionStorage.setItem(TokenExpiration, tokenExp)
}
export function removeTokenExpiration() {
  return sessionStorage.removeItem(TokenExpiration)
}
// Refresh Token
export function getRefreshToken() {
  return sessionStorage.getItem(RefreshToken)
}
export function setRefreshToken(refreshToken) {
  return sessionStorage.setItem(RefreshToken, refreshToken)
}
export function removeRefreshToken() {
  return sessionStorage.removeItem(RefreshToken)
}