import request from '@/utils/request'

export function getRoles() {
  return request({
    url: 'https://5f6d69c360cf97001641ad82.mockapi.io/api/v1/roles',
    method: 'get',
  })
}

export function getAllUsers() {
  return request({
    url: 'users',
    method: 'get',
  })
}

export function getUsers() {
  return request({
    url: 'users?status=1&limit=10000',
    method: 'get',
  })
}

export function getInactiveUsers() {
  return request({
    url: 'users?status=2&limit=10000',
    method: 'get',
  })
}

export function getForapprovalUsers() {
  return request({
    url: 'users?status=3&limit=10000',
    method: 'get',
  })
}

export function getAllPolicies() {
  return request({
    url: 'policy',
    method: 'get',
  })
}

export function getAllModules() {
  return request({
    url: 'policy/routes/all',
    method: 'get',
  })
}

export function getAllRoles() {
  return request({
    url: 'role?status=1',
    method: 'get',
  })
}

export function getActiveRoles() {
  return request({
    url: 'role?status=1',
    method: 'get',
  })
}

export function getInactiveRoles() {
  return request({
    url: 'role?status=0',
    method: 'get',
  })
}

export function createUser(data) {
  return request({
    url: 'users/add',
    method: 'post',
    data
  })
}

export function getPolicyMapByRoleId(roleId) {
  return request({
    url: 'policy/byrole/'+roleId,
    method: 'get',
  })
}

export function createRole(data) {
  return request({
    url: 'role',
    method: 'post',
    data
  })
}

export function updateRole(data, roleId) {
  return request({
    url: 'role/' +roleId+ '/update',
    method: 'put',
    data
  })
}

export function activateRole(data, roleId) {
  return request({
    url: 'role/' +roleId+ '/update',
    method: 'put',
    data
  })
}

export function deleteRole(data, roleId) {
  return request({
    url: 'role/' +roleId+ '/delete',
    method: 'put',
    data
  })
}

export function postPolicy(data) {
  return request({
    url: 'policymap/bulk',
    method: 'post',
    data
  })
}

export function getGrantsMapById(roleId) {
  return request({
    url: 'grantsmap/byrole/' + roleId,
    method: 'get',
  })
}

export function postGrantsMap(data) {
  return request({
    url: 'grantsmap/bulk',
    method: 'post',
    data
  })
}

export function getAllRolemap() {
  return request({
    url: 'rolemap',
    method: 'get',
  })
}

export function postRolemap(data) {
  return request({
    url: 'rolemap',
    method: 'post',
    data
  })
}

export function updateUser(data, userId) {
  return request({
    url: 'users/' + userId,
    method: 'put',
    data
  })
}

export function approveUser(data, userId) {
  return request({
    url: 'users/approve/' + userId,
    method: 'put',
    data
  })
}

export function deleteRolemap(data, rolemapId) {
  return request({
    url: 'rolemap/'+ rolemapId +'/delete',
    method: 'put',
    data
  })
}

export function resetUserPassword(data, userId) {
  return request({
    url: 'users/reset/pw/' + userId,
    method: 'put',
    data
  })
}

export function getDepartment() {
  return request({
    url: 'sol',
    method: 'get',
  })
}

//Address Master
export function getProvince() {
  return request({
    url: 'lookup/province',
    method: 'get',
  })
}

export function getCity(mappingId) {
  return request({
    url: 'lookup/city/' + mappingId,
    method: 'get',
  })
}

export function getBrgy(mappingId) {
  return request({
    url: 'lookup/brgy/' + mappingId,
    method: 'get',
  })
}

//Company Master
export function getAllCompany(page, limit) {
  return request({
    url: 'company?page='+page+'&limit='+limit,
    method: 'get',
  })
}

export function getCompanyMaster(item) {
  return request({
    url: 'company/' + item,
    method: 'get',
  })
}

export function getCompanySpecific(item) {
  return request({
    url: 'company/' + item,
    method: 'get',
  })
}

export function addCompany(data) {
  return request({
    url: 'company',
    method: 'post',
    data
  })
}

export function editCompany(data, id) {
  return request({
    url: 'company/'+id+'/update',
    method: 'put',
    data
  })
}

export function getRegion() {
  return request({
    url: 'company/deped/region/',
    method: 'get',
  })
}

export function getSchool(page, region, limit) {
  return request({
    url: 'company/deped?page='+page+'&limit='+limit+'&region='+region,
    method: 'get',
  })
}

export function getSpecificSchool(region, search) {
  return request({
    url: 'company/deped/maria?region='+region+'&company='+search,
    method: 'get',
  })
}



//Scheme Master
export function getAllScheme() {
  return request({
    url: 'scheme?page=1&limit=1000',
    method: 'get',
  })
}

export function replicateScheme() {
  return request({
    url: 'core/extract/product',
    method: 'get',
  })
}


export function getSmlScheme() {
  return request({
    url: 'scheme/sml',
    method: 'get',
  })
}

export function getLguScheme() {
  return request({
    url: 'scheme/lgu',
    method: 'get',
  })
}

export function getPucScheme() {
  return request({
    url: 'scheme/puc',
    method: 'get',
  })
}

export function getAelScheme() {
  return request({
    url: 'scheme/agency',
    method: 'get',
  })
}

export function getDepedScheme() {
  return request({
    url: 'scheme/deped',
    method: 'get',
  })
}

export function getSpecificScheme(id) {
  return request({
    url: 'scheme/details?scheme_id='+id,
    method: 'get',
  })
}

export function addScheme(data) {
  return request({
    url: 'scheme',
    method: 'post',
    data
  })
}

export function editScheme(id , data) {
  return request({
    url: 'scheme/update?scheme_id=' + id,
    method: 'put',
    data
  })
}

export function addSchemeMapping(data) {
  return request({
    url: 'scheme/mapping/company',
    method: 'post',
    data
  })
}

export function addSchemeToCompanyMapping(data) {
  return request({
    url: 'scheme/mapping',
    method: 'post',
    data
  })
}

export function updateSchemeMapping(data, id) {
  return request({
    url: 'scheme/mapping/update?mapping_id=' + id,
    method: 'put',
    data
  })
}


export function getSchemeMappingSpecific(item, id) {
  return request({
    url: 'scheme/mapping/'+ item +'?company_id=' + id,
    method: 'get',
  })
}

export function deleteSchemeMapping(id) {
  return request({
    url: 'scheme/mapping/archive?mapping_id=' + id,
    method: 'put'
  })
}

export function getSchemeToCompanyMapping(id) {
  return request({
    url: 'scheme/mapping?scheme_id=' + id,
    method: 'get'
  })
}

//Branch Master
export function getBranch() {
  return request({
    url: 'sol',
    method: 'get',
  })
}

export function getSpecificBranch(id) {
  return request({
    url: 'sol?sys_id=' + id,
    method: 'get',
  })
}

export function addBranch(data) {
  return request({
    url: 'sol',
    method: 'post',
    data
  })
}

export function editBranch(id , data) {
  return request({
    url: 'sol?sys_id=' + id,
    method: 'put',
    data
  })
}

export function addBranchMapping(data) {
  return request({
    url: 'sol/company/bulk',
    method: 'post',
    data
  })
}

export function addBranchToCompanyMapping(data) {
  return request({
    url: 'sol/company/mapping/bulk',
    method: 'post',
    data
  })
}

export function updateBranchMapping(data, id) {
  return request({
    url: 'sol/company?mapping_id=' + id,
    method: 'post',
    data
  })
}

export function getBranchMappingSpecific(item, id) {
  return request({
    url: 'sol/company/'+ item +'/details?company_id=' + id,
    method: 'get',
  })
}

export function deleteBranchMapping(id) {
  return request({
    url: 'sol/company/archive?mapping_id=' + id,
    method: 'put'
  })
}

export function getBranchToCompanyMapping(id) {
  return request({
    url: 'sol/company/mapping?sol_sys_id=' + id,
    method: 'get',
  })
}

export function getBranchByType(type) {
  return request({
    url: 'sol/type?company=' + type,
    method: 'get',
  })
}

//List Of Values
export function addLovTable(data) {
  return request({
    url: 'lookup/table',
    method: 'post',
    data
  })
}

export function addLovValue(data) {
  return request({
    url: 'lookup/values/bulk',
    method: 'post',
    data
  })
}

export function editLOV(id , data) {
  return request({
    url: 'lookup/table/' + id,
    method: 'put',
    data
  })
}
  
export function getLovTable() {
  return request({
    url: 'lookup/table',
    method: 'get',
  })
}

export function getLovValues(vTableId) {
  return request({
    url: 'lookup/values/' + vTableId,
    method: 'get',
  })
}

export function getAllLovValues(vTableId) {
  return request({
    url: 'lookup/values/' + vTableId + '/all',
    method: 'get',
  })
}

export function getLovValuesbyID(vTableId, SysID) {
    return request({
    url: 'lookup/values/'+ vTableId +'?lv_id=' + SysID ,
      method: 'get',
    })
}  

export function updateLovValues(data, vTableId) {
  return request({
    url: 'lookup/values/' + vTableId,
    method: 'put',
    data
  })
}

export function activateLovValues(id) {
  return request({
    url: 'scheme/activate?scheme_id=' + id,
    method: 'put'
  })
}

export function deactivateLovValues(id) {
  return request({
    url: 'scheme/deactivate?scheme_id=' + id,
    method: 'put'
  })
}

export function getIp() {
  return request({
    url: 'https://api.ipify.org/?format=json',
    method: 'get',
  })
}

export function sendFeedback(data) {
  return request({
    url: 'feedback',
    method: 'post',
    data
  })
}

export function getDealer() {
  return request({
    url: 'dealers',
    method: 'get',
  })
}

export function getSpecificDealer(id) {
  return request({
    url: 'dealers/' + id,
    method: 'get',
  })
}

export function addDealer(data) {
  return request({
    url: 'dealers',
    method: 'post',
    data
  })
}

export function editDealer(data, id) {
  return request({
    url: 'dealers/' + id,
    method: 'put',
    data
  })
}

export function getDealerBranch() {
  return request({
    url: 'dealerBranch?page=1&limit=999',
    method: 'get',
  })
}

export function getDealerBranchSpecific(id) {
  return request({
    url: 'dealerBranch/'+id,
    method: 'get',
  })
}

export function addDealerBranch(data) {
  return request({
    url: 'dealerBranch',
    method: 'post',
    data
  })
}

export function editDealerBranch(id, data) {
  return request({
    url: 'dealerBranch/' + id,
    method: 'put',
    data
  })
}

export function addDealerBranchMapping(data) {
  return request({
    url: 'dealerBranchSolMap/bulk',
    method: 'post',
    data
  })
}

export function editDealerBranchMapping(data) {
  return request({
    url: 'dealerBranchSolMap/bulk/update',
    method: 'put',
    data
  })
}

export function getDealerPricelist() {
  return request({
    url: 'dealerPriceGroup',
    method: 'get',
  })
}

export function getSpecificDealerPricelist(id) {
  return request({
    url: 'dealerPriceGroup/'+id,
    method: 'get',
  })
}

export function addDealerPricelist(data) {
  return request({
    url: 'dealerPriceGroup',
    method: 'post',
    data
  })
}

export function editDealerPricelist(id, data) {
  return request({
    url: 'dealerPriceGroup/'+id,
    method: 'put',
    data
  })
}

export function getSpecificDealerPricelistData(id) {
  return request({
    url: 'dealerPriceData/byPLG/'+id,
    method: 'get',
  })
}



export function addDealerPricelistData(data) {
  return request({
    url: 'dealerPriceData/bulk',
    method: 'post',
    data
  })
}

export function editDealerPricelistData(data) {
  return request({
    url: 'dealerPriceData/bulk/update',
    method: 'put',
    data
  })
}


export function addDealerSaMapping(data) {
  return request({
    url: 'saBranchMap/bulk',
    method: 'post',
    data
  })
}

export function editDealerSaMapping(data) {
  return request({
    url: 'saBranchMap/bulk/update',
    method: 'put',
    data
  })
}

export function getSpecificDealerSa(id) {
  return request({
    url: 'saBranchMap/byDBID/' + id,
    method: 'get',
  })
}

export function getSpecificDealerBranch(id) {
  return request({
    url: 'dealerBranchSolMap/byDBID/' + id,
    method: 'get',
  })
}
export function getOnlineBilling() {
  return request({
    url: 'rpsu',
    method: 'get',
  })
}

export function getSpecificOnlineBilling(id) {
  return request({
    url: 'rpsu/' + id,
    method: 'get',
  })
}

export function addOnlineBilling(data) {
  return request({
    url: 'rpsu',
    method: 'post',
    data
  })
}

export function editOnlineBilling(id, data) {
  return request({
    url: 'rpsu/' + id,
    method: 'put',
    data
  })
}

export function getAllBCPLGU() {
  return request({
    url: 'billing/LGU/list?limit=1000',
    method: 'get',
  })
}

export function getSpecificBCPLGU(id) {
  return request({
    url: 'billing/' + id,
    method: 'get',
  })
}

export function editBCPLGU(id, data) {
  return request({
    url: 'billing/update/' + id,
    method: 'put',
    data
  })
}

export function addBCPLGU(data) {
  return request({
    url: 'billing/add/LGU',
    method: 'post',
    data
  })
}

