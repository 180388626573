const getters = {
  app_api_domain: state => state.app.app_api_domain,
  app_base_api: state => state.app.app_base_api,
  app_environment: state => state.app.app_environment,
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  user_info: state => state.user.user_info,
  roles: state => state.user.roles,
  user_id: state => state.user.user_id,
  permission_routes: state => state.permission.routes,
  is_pw_temp: state => state.user.is_pw_temp,
  login_loading: state => state.app.login_loading,
  modal_loading: state => state.talktomaria.modal_loading,
}
export default getters
