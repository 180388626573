import moment from 'moment/moment'

export default {

    hasFieldValue(source, field) {
        return source && source.hasOwnProperty(field) && source[field]
    },

    parseJSON(value) {
        return JSON.parse(value)
    },

    isNumber(event) {
        event = (event) ? event : window.event;
        let charCode = (event.which) ? event.which : event.keyCode;

        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            event.preventDefault()
            return
        }

        return true
    },

    amount(value) {
        if (isNaN(value) || !value) {
            return '0.00'
        }

        value = parseFloat(value).toFixed(2)
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    currency(value, toFixed = 0) {
        return parseFloat(value.toString().replace(/,/g, '')).toFixed(toFixed)
    },

    toDateAndTimeStartOfDayFormat(value) {
        return moment(value).format('YYYY-MM-DD 00:00:00')
    },

    toDateAndTimeEndOfDayFormat(value) {
        return moment(value).format('YYYY-MM-DD 23:59:59')
    },

    toDateFormat(value) {
        return moment(value).format('LLL')
    },

    humanize(value) {
        let i, frags = value.split('_')

        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }

        return frags.join(' ')
    },

    titleCase(value) {
        const arr = value.split('_')
        const result = []

        for (const word of arr) {
            result.push(word.charAt(0).toUpperCase() + word.slice(1))
        }

        return result.join(' ')
    }

}