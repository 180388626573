<template>
    <div>
        <b-navbar type="dark" variant="csb" fixed="absolute" sticky style="box-shadow: 0px 1px 4px 2px grey">
            <slot></slot>

            <b-navbar-brand class="ml-3" href="#">
                <img src="../../assets/images/logo5.jpg" style="width:10rem" :hidden="toggleTheme1">
                <!-- <img src="../../assets/images/logo6.jpg" style="width:10rem" :hidden="toggleTheme2"> -->
            </b-navbar-brand>

            <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

            <!-- <b-collapse id="nav-collapse" is-nav> -->


            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <!-- <b-nav-item class="ml-2">
                        <b-icon-question-circle-fill v-bind:style="{boxShadow: boxshadowHelp, backgroundColor: backgroundHelp, cursor: 'pointer'}"
                                                     style="color:white; border-radius:50%"
                                                     @mouseover="boxshadowHelp=shadowButton, backgroundHelp=backgroundButton"
                                                     @mouseout="boxshadowHelp='none', backgroundHelp='transparent'"
                                                     @click="showTooltipHelp=false"
                                                     id="tooltiphelp"></b-icon-question-circle-fill>
                        <b-tooltip target="tooltiphelp" triggers="hover" boundary-padding="40" :show.sync="showTooltipHelp">
                            Help
                        </b-tooltip>
                    </b-nav-item>

                    <b-nav-item class="ml-2">
                        <b-icon-bell-fill v-bind:style="{boxShadow: boxshadowBell, backgroundColor: backgroundBell, cursor: 'pointer'}"
                                          style="color:white; border-radius:50%;"
                                          @mouseover="boxshadowBell=shadowButton, backgroundBell=backgroundButton"
                                          @mouseout="boxshadowBell='none', backgroundBell='transparent'"
                                          @click="showTooltipNotif=false"
                                          id="tooltipnotif"></b-icon-bell-fill>
                        <b-tooltip target="tooltipnotif" triggers="hover" boundary-padding="40" :show.sync="showTooltipNotif">
                            Notification
                        </b-tooltip>
                    </b-nav-item> -->

                <b-nav-item-dropdown class="ml-2" right no-caret>
                    <!-- Using 'button-content' slot -->

                    <template #button-content>
                        <div v-if="user_info.sso_profile_pic" style="overflow: hidden;color:white; border-radius:50%;"
                            v-bind:style="{ boxShadow: boxshadowProfile, backgroundColor: backgroundProfile, cursor: 'pointer' }"
                            @mouseover="boxshadowProfile = shadowButton, backgroundProfile = backgroundButton"
                            @mouseout="boxshadowProfile = 'none', backgroundProfile = 'transparent'"
                            @click="showTooltipUser = false" id="tooltipuser">
                            <img v-if="user_info.sso_profile_pic" :src="user_info.sso_profile_pic" alt=""
                                style="width:30px">
                        </div>
                        <b-icon-person-circle v-else
                            v-bind:style="{ boxShadow: boxshadowProfile, backgroundColor: backgroundProfile, cursor: 'pointer' }"
                            style="color:white; border-radius:50%;"
                            @mouseover="boxshadowProfile = shadowButton, backgroundProfile = backgroundButton"
                            @mouseout="boxshadowProfile = 'none', backgroundProfile = 'transparent'"
                            @click="showTooltipUser = false" id="tooltipuser"></b-icon-person-circle>
                    </template>
                    <b-card bg-variant="white" style="width:300px; border: 0px;">
                        <!-- <b-icon icon="person-circle"  font-scale="5"></b-icon><br><p></p> -->
                        <a>{{ fname }} <a v-if="mname">{{ mname }}. </a>{{ lname }}</a>
                        <br>
                        <a style="font-size:15px; opacity:70%">{{ username }} </a>
                        <br>
                        <hr>
                        <a>Roles: </a>
                        <br>
                        <a v-for="num in roles" :key="num.index" style="font-size:15px; opacity:70%">{{ num }} <br></a>
                        <br>
                        <a>Branch: </a>
                        <br>
                        <a style="font-size:15px; opacity:70%">{{ user_dept }}</a>
                        <!-- <b-button   pill variant="outline-secondary"  v-b-toggle.avatar  @click="showManageUser">Manage your User Account</b-button> -->
                        <br>
                        <hr style="margin-bottom:0px">
                        <b-row @mousedown="write_feedback = true; refresh_key++" @click="openFeedback()"
                            variant="outline-secondary" class="button-hover">
                            <b-container>
                                Send Feedback
                            </b-container>
                        </b-row>

                        <hr style="margin-top:0px; margin-bottom:0px">
                        <b-row @mousedown="write_feedback = true; refresh_key++" @click="logout()"
                            variant="outline-secondary" class="button-hover">
                            <b-container>
                                Sign Out
                            </b-container>
                        </b-row>
                        <!-- <b-button     variant="outline-secondary">Sign out</b-button> -->
                    </b-card>

                    <!-- <b-tooltip target="tooltipuser" triggers="hover" boundary-padding="40" :show.sync="showTooltipUser">
                                <a>{{fullname}}</a><br><a>{{username}}</a>
                            </b-tooltip> -->
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <!-- </b-collapse> -->
        </b-navbar>

        <manage-account></manage-account>
        <feedback-modal :creator="fullname" :anonymous="anonymous" v-if="write_feedback == true"
            :key="refresh_key"></feedback-modal>
    </div>
</template>

<style scoped>
.button-hover {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.button-hover:hover {
    background-color: #F5A847;
    cursor: pointer;
}
</style>

<script>
import { mapGetters } from 'vuex'
import ManageAccount from '../../components/ManageAccount.vue';

import jwt_decode from "jwt-decode";
import { getToken } from '@/utils/auth';

import { getDepartment } from '@/api/settings'

import FeedbackModal from './FeedbackModal.vue'
// import { nextTick } from 'vue/types/umd';

export default {
    components: {
        ManageAccount,
        FeedbackModal
    },
    data: function () {
        return {
            avatar: false,
            variants: "transparent",
            username: "",
            fullname: "",
            roles: [],
            user_auth: '',
            user_dept: '',
            department: '',
            anonymous: false,
            write_feedback: false, //rendering feedback modal
            refresh_key: 1,

            fname: '',
            mname: '',
            lname: '',

            target1: '',
            target2: '',
            sliding: null,
            width1: '290px', //burgermenu width
            display1: 'none', //overlay
            left1: '-290px', // burgermenu sliding animation

            //themes
            toggleTheme1: false,
            toggleTheme2: true,
            headerColor: "#4f2684",
            shadowButton: "0px 0px 1px 11px #823fd9",
            backgroundButton: "#823fd9",

            //burgermenu overlay
            background1: 'white',
            opacity1: '0',

            //tooltips onhover button highlight
            boxshadowNav: 'none',
            backgroundNav: 'transparent',
            boxshadowHelp: 'none',
            backgroundHelp: 'transparent',
            boxshadowBell: 'none',
            backgroundBell: 'transparent',
            boxshadowDots: 'none',
            backgroundDots: 'transparent',
            boxshadowProfile: 'none',
            backgroundProfile: 'transparent',

            //tooltips
            showTooltipNav: false,
            showTooltipHelp: false,
            showTooltipNotif: false,
            showTooltipSetting: false,
            showTooltipUser: false,


            //navbar highlights
            highlight1: 'white',
            highlight2: 'white',
            highlight3: 'white',
            highlight4: 'white',
            highlight5: 'white',
            highlight6: 'white',
            highlight7: 'white',
            highlight8: 'white',
            highlight9: 'white',
            highlight10: 'white',
            highlight11: 'white',

            sidebar: false,

            data: []

        }
    },
    created() {

    },
    methods: {
        async logout() {
            await this.$store.dispatch('user/logoutApi')
            await this.$store.dispatch('user/logout')
            // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
            this.$router.push(`/login`)
        },
        showManageUser: function () {
            this.$bvModal.show('ManageAccount')
        },

        open: function () {
            this.width1 = '250px'
        },
        pass: function (value) {
            if (value.length != 0) {
                this.target2 = true
            }
            else {
                this.target2 = false
            }
        },
        handleClick: function () {
        },
        theme: function () {
            if (this.toggleTheme1 == false) {
                this.toggleTheme1 = true
                this.toggleTheme2 = false
                this.headerColor = "#F5A847"
                this.shadowButton = "0px 0px 1px 8px #fc8403"
                this.backgroundButton = "#fc8403"
            }
            else {
                this.toggleTheme1 = false
                this.toggleTheme2 = true
                this.headerColor = "#4f2684"
                this.shadowButton = "0px 0px 1px 8px #823fd9"
                this.backgroundButton = "#823fd9"
            }
        },

        openFeedback() {
            this.$bvModal.show('FeedbackModal')
        },



        loadDepartment() {
            getDepartment().then(response => {
                var data = response.data.data

                for (let i = 0; i <= data.length - 1; i++) {
                    if (this.user_dept == data[i].sol_id) {
                        this.department = data[i].branch_name
                    }
                }

            }).catch(error => { // post policy catch

            })
        }
        /*logout(){
            localStorage.authenticate = '401';
            this.$router.push('/')
        }*/
    },

    computed: {
        ...mapGetters([
            'user_info'
        ]),
    },

    mounted() {
        var token = getToken()
        var decoded = jwt_decode(token);
        this.roles = decoded.roles;
        // this.axios.get('https://new-los-api-wivlpotrwa-an.a.run.app/v1/newlos/users/' + decoded.sys_id, { 'headers': { 'authtoken': getToken() } })
        //         .then(response => (
        //             this.data = response.data.data,
        //             this.username = this.data[0].email,
        //             this.fname = this.data[0].fname,
        //             this.mname = this.data[0].mname.slice(0,1),
        //             this.lname = this.data[0].lname,
        //             this.fullname = (this.data[0].fname + ' ' + this.data[0].mname.slice(0,1) + '. ' + this.data[0].lname),
        //             this.user_auth = this.data[0].user_authorization,
        //             this.user_dept = this.data[0].department,
        //             localStorage.fullname = this.fullname,

        //         this.$nextTick(() => {
        //             this.loadDepartment()
        //         })  
        // ));
        this.username = this.user_info.email;
        this.fname = this.user_info.fname;
        this.mname = this.user_info.mname.slice(0, 1);
        this.lname = this.user_info.lname;
        this.user_auth = this.user_info.user_authorization;
        this.user_dept = this.user_info.department + ' - ' + this.user_info.branch_name;
        localStorage.fullname = this.fullname;


    }


}
</script>
