<template>
    <div class="app-wrapper">
        <navbar>

            <span style="cursor:pointer; font-size:20px">
                <b-icon-list class="ml-2" v-bind:style="{ boxShadow: boxshadowNav, backgroundColor: backgroundNav }"
                    style="color:white; border-radius:50%"
                    @click="burgerMenuSidebar(), background1 = 'black', opacity1 = '0.5', display1 = 'block', left1 = '0px', showTooltipNav = false"
                    @mouseover="boxshadowNav = shadowButton, backgroundNav = backgroundButton"
                    @mouseout="boxshadowNav = 'none', backgroundNav = 'transparent'"
                    id="tooltipnav"></b-icon-list></span>

        </navbar>
        <!-- <h6>Width: {{ window.width }} px  }}</h6> -->
        <div style="overflow: auto;">
            <!-- <div :id="slider"> -->
            <div class="sidenav" :class="hoverSize">

                <!-- *******  DIVIDER ********* -->
                <div v-if="showAddress">
                    <div v-if="!label"
                        style="width:100%; color:black; font-size:16px; padding-left:2rem; margin-top:1rem">
                        Masters
                    </div>
                    <!-- <div v-if="label" class="border-top" style="margin-bottom: 19px; margin-top: 20px; margin-top: 20px; width: 70px;"></div> -->
                    <div v-if="label" style="margin-bottom: 19px; margin-top: 20px; width: 70px; padding-top:0.05rem">
                    </div>
                </div>
                <!-- *******  DIVIDER ********* -->

                <!-- Address -->
                <div v-if="showAddress" id="addressTooltip" class="hoverable">
                    <b-link :to="address" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/address.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Address</span></b-link>
                </div>
                <b-tooltip v-if="label" target="addressTooltip" triggers="hover" placement="right">
                    Address
                </b-tooltip>
                <!-- Address -->

                <!-- Branch -->
                <div v-if="showBranch" id="branchTooltip" class="hoverable">
                    <b-link :to="branch" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/branch master.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Branch</span></b-link>
                </div>
                <b-tooltip v-if="label" target="branchTooltip" triggers="hover" placement="right">
                    Branch
                </b-tooltip>
                <!--Branch -->

                <!-- Company -->
                <div v-if="showCompany" id="companyTooltip" class="hoverable">
                    <b-link :to="company" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/company.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Company</span></b-link>
                </div>
                <b-tooltip v-if="label" target="companyTooltip" triggers="hover" placement="right">
                    Company
                </b-tooltip>
                <!-- Company -->

                <!-- Scheme -->
                <div v-if="showScheme" id="schemeTooltip" class="hoverable">
                    <b-link :to="scheme" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/scheme master.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Scheme</span></b-link>
                </div>
                <b-tooltip v-if="label" target="schemeTooltip" triggers="hover" placement="right">
                    Scheme
                </b-tooltip>
                <!-- Scheme -->

                <!-- Dealer -->
                <div v-if="showDealer" id="dealerTooltip" class="hoverable">
                    <b-link :to="dealer" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/Dealer.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Dealer</span></b-link>
                </div>
                <b-tooltip v-if="label" target="dealerTooltip" triggers="hover" placement="right">
                    Dealer
                </b-tooltip>
                <!-- Dealer -->

                <!-- Dealer-branch -->
                <div v-if="showDealerBranch" id="dealerBranchTooltip" class="hoverable">
                    <b-link :to="dealerBranch" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/Dealer Mapping.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Dealer Branch</span></b-link>
                </div>
                <b-tooltip v-if="label" target="dealerBranchTooltip" triggers="hover" placement="right">
                    Dealer Branch
                </b-tooltip>
                <!-- Dealer-branch -->

                <!-- Dealer-pricelist -->
                <div v-if="showDealerPricelist" id="dealerPricelistTooltip" class="hoverable">
                    <b-link :to="dealerPricelist" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/Dealer Price.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Dealer Price List</span></b-link>
                </div>
                <b-tooltip v-if="label" target="dealerPricelistTooltip" triggers="hover" placement="right">
                    Dealer Price List
                </b-tooltip>
                <!-- Dealer-branch -->

                <!-- billing  -->
                <div v-if="showOnlineBilling" id="onlineBillingTooltip" class="hoverable">
                    <b-link :to="onlineBilling" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/list of values.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Deped Online Billing</span></b-link>
                </div>
                <b-tooltip v-if="label" target="onlineBillingTooltip" triggers="hover" placement="left">
                    Deped Online Billing
                </b-tooltip>
                <!-- billing  -->

                <!-- credex -->
                <div v-if="showCredex" id="credexTooltip" class="hoverable">
                    <b-link :to="credexConverter" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/list of values.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Credex File Converter</span></b-link>
                </div>
                <b-tooltip v-if="label" target="credexTooltip" triggers="hover" placement="left">
                    Credex File Converter
                </b-tooltip>
                <!-- credex -->

                <!-- BCP  -->
                <div v-if="showBCP" id="BCPTooltip" class="hoverable">
                    <b-link :to="bcp" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/list of values.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> BCP</span></b-link>
                </div>
                <b-tooltip v-if="label" target="BCPTooltip" triggers="hover" placement="left">
                    Beyond Deped
                </b-tooltip>
                <!-- BCP  -->

                <!-- Approver Limit -->
                <div v-if="showApproverLimit" id="ApproverLimitTooltip" class="hoverable">
                    <b-link :to="approverLimit" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/list of values.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Approver Limit</span></b-link>
                </div>
                <b-tooltip v-if="label" target="ApproverLimitTooltip" triggers="hover" placement="left">
                    Approver Limit
                </b-tooltip>

                <!-- Month Lag -->
                <div v-if="showMonthLags" id="MonthLagsTooltip" class="hoverable">
                    <b-link :to="monthLags" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/images/calendar.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Month Lags</span></b-link>
                </div>
                <b-tooltip v-if="label" target="MonthLagsTooltip" triggers="hover" placement="left">
                    Month Lags
                </b-tooltip>


                <!-- ****** divider ******** -->
                <div v-if="showLov">
                    <div v-if="!label"
                        style="width:100%; color:black; font-size:16px; padding-left:2rem; margin-top:1rem">
                        Settings
                    </div>
                    <div v-if="label" class="border-top"
                        style="margin-bottom: 19px; margin-top: 20px; margin-top: 20px; width: 70px;"></div>
                </div>
                <!-- ****** divider ******** -->

                <!-- LOV  -->
                <div v-if="showLov" id="lovTooltip" class="hoverable">
                    <b-link :to="Lov" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/list of values.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> List of Values</span></b-link>
                </div>
                <b-tooltip v-if="label" target="lovTooltip" triggers="hover" placement="right">
                    List of Values
                </b-tooltip>
                <!-- LOV  -->

                <!-- System Log -->
                <div v-if="showLogs" id="LogsTooltip" class="hoverable">
                    <b-link :to="logs" style="font-size:18px; text-decoration: none;">
                        <img src="../assets/central/list of values.png" style="width:40px; margin-right:1rem"><span
                            v-if="!label"> Logs</span></b-link>
                </div>
                <b-tooltip v-if="label" target="LogsTooltip" triggers="hover" placement="left">
                    Logs
                </b-tooltip>
            </div>
            <!-- </div> -->
            <app-main :class="contentStatusSidebar" />
        </div>

        <b-modal id="inactivityModal" ref="modal" size="lg" no-close-on-backdrop no-close-on-esc
            title="Automatic Logout For Your Protection" @ok="timeout('true')" @cancel="logout()"
            cancel-variant="danger" ok-title="Keep me logged in" cancel-title="Logout" ok-variant="primary" scrollable>
            <p class="my-4">Due to inactivity, your current work session is about to expire. For your security, Access
                Management sessions automatically close after 15 minutes of inactivity</p>
            <p>Your current session will expire in {{(session_timeout) }} seconds</p>
        </b-modal>
    </div>
</template>

<script>
import { AppMain, Navbar } from './components'
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
    name: 'Layout',
    components: {
        AppMain,
        Navbar,
    },
    data: function () {
        return {
            window: {
                width: 0,
                height: 0
            },
            //themes
            toggleTheme1: false,
            toggleTheme2: true,
            headerColor: "#4f2684",
            shadowButton: "0px 0px 1px 11px #823fd9",
            backgroundButton: "#823fd9",
            //tooltips
            showTooltipNav: false,
            //tooltips onhover button highlight
            boxshadowNav: 'none',
            backgroundNav: 'transparent',
            label: true,
            statusSidebar: true,
            //navbar highlights
            highlight1: 'white',
            highlight2: 'white',
            highlight3: 'white',
            highlight4: 'white',
            highlight5: 'white',
            highlight6: 'white',
            highlight7: 'white',
            highlight8: 'white',
            highlight9: 'white',
            highlight10: 'white',
            highlight11: 'white',

            showAddress: false,
            showBranch: false,
            showCompany: false,
            showScheme: false,
            showDealer: false,
            showDealerBranch: false,
            showDealerPricelist: false,
            showLov: false,
            showOnlineBilling: false,
            showCredex: false,
            showBCP: false,
            showMonthLags: false,
            showLogs: false,
            showApproverLimit: false,

            session_timeout: 0,

            signout: ''

        }
    },

    watch: {

        isIdle(newVal, oldVal) {
            if (newVal == true) {
                // this.showMsgBoxTwo()
                this.$bvModal.show('inactivityModal')
                this.timeout('false')
            }
            else {
                //do nothing
            }
        }
    },


    computed: {

        isIdle() {
            return this.$store.state.idleVue.isIdle;
        },
        contentStatusSidebar() {
            return this.statusSidebar ? 'contenthide' : 'contentshow';
        },
        slider() {
            return this.statusSidebar ? 'sidebarCollapsed' : 'sidebarShow';
        },
        hoverSize() {
            return this.statusSidebar ? 'hoversize-small' : 'hoversize-large';
        },
        ...mapGetters([
            'permission_routes',
            'roles'
        ]),

        address() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/address'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/address'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'address' } }
            }
        },

        branch() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/branch'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/branch'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'branch' } }
            }
        },

        company() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/company'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/company'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'company' } }
            }
        },

        scheme() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/scheme'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/scheme'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'scheme' } }
            }
        },

        dealer() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/dealer'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/dealer'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'dealer' } }
            }
        },

        dealerBranch() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/dealerBranch'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/dealerBranch'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'dealerBranch' } }
            }
        },

        dealerPricelist() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/dealerPricelist'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/dealerPricelist'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'dealerPricelist' } }
            }
        },



        Lov() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/lov'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/lov'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'lov' } }
            }
        },

        onlineBilling() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/onlineBilling'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/onlineBilling'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'onlineBilling' } }
            }
        },

        credexConverter() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/credex'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/credex'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'credex' } }
            }
        },


        bcp() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/BCP'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/BCP'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'BCP' } }
            }
        },

        monthLags() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/monthLags'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/monthLags'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'monthLags' } }
            }
        },

        approverLimit() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/approverLimit'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/approverLimit'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'approverLimit' } }
            }
        },

        logs() {
            var temp = this.permission_routes.filter(i => {
                return i.path === '/logs'
            })

            if (Object.keys(temp[0].children).length !== 0) {
                return '/logs'
            } else {
                return { name: 'ForbiddenPage', params: { id: 'logs' } }
            }
        },

    },
    methods: {

        timeout(x) {
            if (x == 'true') {
                clearTimeout(this.signout)
            }
            else if (x == 'false') {
                this.session_timeout = 60
                this.signout = setTimeout(() => this.logout(), 60000) // 1 MINUTE TO CANCEL LOGOUT
                this.countdownTimer()
            }
        },

        countdownTimer() {
            if (this.session_timeout > 0) {
                setTimeout(() => {
                    this.session_timeout = this.session_timeout - 1
                    this.countdownTimer()
                }, 1000)
            }
        },

        async logout() {
            clearTimeout(this.signout)
            await this.$store.dispatch('user/logoutApi')
            await this.$store.dispatch('user/logout')
            // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
            this.$router.push(`/login`)
        },

        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;

            if (this.window.width <= 800) {
                this.statusSidebar = true
                this.label = true

            }
        },
        handleHover(hovered) {
            if (hovered == true) {
                this.hoverable = 'hovered-true'
            } else {
                this.hoverable = 'hovered-false'
            }

        },
        burgerMenuSidebar() {

            if (this.window.width >= 800) {
                this.statusSidebar = !this.statusSidebar;
                if (this.statusSidebar == false) {                                // pagbukas ng sidebar
                    setTimeout(() => this.label = this.statusSidebar, 100);
                } else {                                                        // pagsara ng sidebar
                    this.label = this.statusSidebar
                }
            }
        },
    },
    created() {
        // this.burgerMenuSidebar()
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        var temp = this.permission_routes.filter(i => {
            return i.path === '/address' ||
                i.path === '/branch' ||
                i.path === '/company' ||
                i.path === '/scheme' ||
                i.path === '/dealer' ||
                i.path === '/dealerBranch' ||
                i.path === '/dealerPricelist' ||
                i.path === '/lov' ||
                i.path === '/onlineBilling' ||
                i.path === '/credex' ||
                i.path === '/BCP' ||
                i.path === '/monthLags' ||
                i.path === '/approverLimit' ||
                i.path === '/logs'
        })

        // if(Object.keys(temp[0].children).length !== 0) {
        //     return '/talktomaria'
        // } else {
        //     return {name: 'ForbiddenPage', params: {id: 'talktomaria'}}
        // }
        const roles = store.getters.roles
        temp.forEach(i => {
            if (i.children.length !== 0) {
                switch (i.path) {
                    case '/address':
                        this.showAddress = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                        break;
                    case '/branch':
                        this.showBranch = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                        break;
                    case '/company':
                        this.showCompany = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                        break;
                    case '/scheme':
                        this.showScheme = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                        break;
                    case '/dealer':
                        this.showDealer = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                        break;
                    case '/dealerBranch':
                        this.showDealerBranch = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                        break;
                    case '/dealerPricelist':
                        this.showDealerPricelist = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                        break;
                    case '/lov':
                        this.showLov = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                        break;
                    case '/onlineBilling':
                        this.showOnlineBilling = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                        break;
                    case '/credex':
                        this.showCredex = roles.includes('Controllership') || roles.includes('Developer ')
                        break;
                    case '/BCP':
                        this.showBCP = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                        break;
                    // case '/monthLags':
                    //     this.showMonthLags = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                    //     break;
                    // case '/approverLimit':
                    //     this.showApproverLimit = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                    //     break;
                    // case '/logs':
                    //     this.showLogs = (roles.includes('Office Administrator') || roles.includes('Administrator') || roles.includes('Developer '))
                    //     break;
                    default:
                    // code block
                }
            }
        })

    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style scoped>
.hoverable:hover {
    background-color: #F5A847;
}

/* adjust width of menu item on hover depending on sidebar status if closed or open */
.hoversize-large {
    width: 260px;
}

.hoversize-small {
    width: 230px;
    padding-right: 160px;
}

/* content show / hide */

.contentshow {
    overflow: auto;
    transition: all 0.3s;
    position: relative;
    margin-left: 260px;
    border-left: 1px solid #eee;

}

.contenthide {
    overflow: auto;
    transition: all 0.3s;
    position: relative;
    margin-left: 70px;
    border-left: 1px solid #eee;

}

/*side navbar*/
.sidenav {
    position: absolute;
    float: left;
    overflow: scroll;
    scrollbar-width: none;
}

@media only screen and (max-width: 1026px) {
    .sidenav {}
}

.sidenav a {
    padding: 8px 8px 8px 14px;
    text-decoration: none;
    font-size: 25px;
    color: Black;
    display: block;
    transition: 0.3s;
}

/* #sidebarShow {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    width: 290px;
   
    padding: 5px;
    overflow-x: hidden;
    overflow-y: hidden;
    border-right: 1px solid #eee;
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - 56px);
    transition: all 0.3s;
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    scrollbar-width: none;
}

#sidebarCollapsed {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    width: 70px;
  
    padding: 5px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    border-right: 1px solid #eee;
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - 56px);
    transition: all 0.3s;
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    scrollbar-width: none;
} */

html {
    overflow: scroll;
    overflow-x: hidden;
}

/*PANG HIDE NG SCROLLBAR*/
::-webkit-scrollbar {
    /*Remove scrollbar space*/
    width: 0px;
    /*  Optional: just make scrollbar invisible */
    background: transparent;
}


/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

.app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

}
</style>
