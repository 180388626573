<template>
    <div :class="{ 'has-logo': showLogo }">
        <logo v-if="showLogo" :collapse="isCollapse" />
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu :default-active="activeMenu" :collapse="isCollapse" :unique-opened="false"
                :collapse-transition="false" mode="vertical">
                <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route"
                    :base-path="route.path" />
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'


export default {
    components: { SidebarItem, Logo },
    computed: {
        ...mapGetters([
            'permission_routes',
            'sidebar'
        ]),
        activeMenu() {
            const route = this.$route
            const { meta, path } = route
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
        showLogo() {
            return this.$store.state.settings.sidebarLogo
        },
        isCollapse() {
            return !this.sidebar.opened
        }
    }
}
</script>
